import moment from "moment";
import NominatifPembiayaanListPdf from "@/components/page/nominatif-pembiayaan-list-pdf";
import vuePDF from "vue-pdf";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

export default {
  name: "NominatifPembiayaan",
  components: {
    NominatifPembiayaanListPdf,
  },
  data() {
    return {
      pdf: {
        src: null,
        pdfToDownload: null,
        numPages: 0,
      },
      property: {
        listElement: {
          nominatif: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            message: "",
            downloading: false,
          },
        },
        animation: {
          NominatifPembiayaan: {
            isLoading: false,
          },
          isDownloadingFile: false,
        },
        modal: {
          showModalPreviewPDFNominatif: false,
        },
      },
      dataForm: {
        userName: "",
        userCode: "",
        officeName: "",
        cabang: "",
        exportContent: "",
        exportToExcel: {
          data: [],
          header: [],
        },
      },
      options: {
        cabang: [],
        eksport: [],
      },
      table: {
        header: {
          nominatif: [
            {
              key: "kodeProduk",
              label: "Kode Produk",
            },
            {
              key: "namaProduk",
              label: "Nama Produk",
            },
            {
              key: "jumlahRekening",
              label: "Jumlah Rekening",
            },
            {
              key: "plafond",
              label: "Plafond",
            },
            {
              key: "ostdPokok",
              label: "Outstanding Pokok",
            },
            {
              key: "ostdMargin",
              label: "Outstanding Margin",
            },
          ],
        },
        data: {
          nominatif: [],
        },
      },
    };
  },
  methods: {
    async onDownloadFile(fileType) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const payload = {
        fileType: fileType,
        officeCode: this.dataForm.cabang,
      };
      this.property.animation.isDownloadingFile = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_SAVE_RESPONSE_DATA",
          endPoint: "report",
          reqUrl: "report/nominatif-loan-product/files",
          payload: payload,
        });
        if (fileType === "pdf") {
          this.printOutWindow(resp.data);
          return;
        }
        var a = document.createElement("a"),
          url = URL.createObjectURL(resp.data);
        a.href = url;
        a.download = `Laporan_Nominatif_Pembiayaan.${
          fileType === "xls" ? "xlsx" : "pdf"
        }`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function () {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
        // eslint-disable-next-line no-empty
      } catch (error) {
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      } finally {
        this.property.animation.isDownloadingFile = false;
      }
    },
    exportPdf() {
      const getUserPayloadFromSession = JSON.parse(
        this.decryptBASE64(sessionStorage.getItem("@vue-session/auth-bundle"))
      );
      this.$refs.nominatifPembiayaanListPdfRef.onOpenPreview({
        tableData: this.table.data.nominatif,
        letterhead: {
          printDate: `${moment().format("DD-MM-YYYY")} ${moment().format(
            "HH:mm:ss"
          )}`,
          officeName: getUserPayloadFromSession.officeName,
          filteredDate: `${moment().format("DD-MM-YYYY")} `,
        },
        printBy: `${getUserPayloadFromSession.userName} ${moment().format(
          "DD-MM-YYYY"
        )} ${moment().format("HH:mm:ss")}`,
      });
    },
    handleChangePilihEksport() {
      this.dataForm.exportToExcel.data = [];
      this.dataForm.exportToExcel.header = [];
      if (this.dataForm.exportContent === "EXCEL") {
        this.table.header.nominatif.map((index) => {
          const field = index.key;
          const label = `${index.label}`;
          this.dataForm.exportToExcel.header.push({ field, label });
        });
        this.table.data.nominatif.map((index) => {
          const kodeProduk = index.kodeProduk;
          const namaProduk = index.namaProduk;
          const jumlahRekening = index.jmlRekening;
          const plafond = `${
            index.plafond
              ? index.plafond.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")
              : 0
          }`;
          const ostdPokok = `${
            index.ostdPokok
              ? index.ostdPokok.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")
              : 0
          }`;
          const ostdMargin = `${
            index.ostdMargin
              ? index.ostdMargin.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")
              : 0
          }`;
          this.dataForm.exportToExcel.data.push({
            kodeProduk,
            namaProduk,
            jumlahRekening,
            plafond,
            ostdPokok,
            ostdMargin,
          });
        });
      } else {
        this.dataForm.exportToExcel.header = [];
        this.dataForm.exportToExcel.data = [];
      }
    },
    helperGetUSerDataFromSession() {
      const getUserPayloadFromSession = JSON.parse(
        this.decryptBASE64(sessionStorage.getItem("@vue-session/auth-bundle"))
      );
      this.dataForm.userName = getUserPayloadFromSession.userName;
      this.dataForm.userCode = getUserPayloadFromSession.userCode;
      this.dataForm.officeName = getUserPayloadFromSession.officeName;
    },
    handleExportNominatifPembiayaanToPdf() {
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "in",
        format: "letter",
      });
      const headerFinal = [];
      const header = this.table.header.nominatif.map((index) => {
        headerFinal.push(index.label);
      });
      const finalDataTable = [];
      var numbering = 1;
      const loopDataTable = this.table.data.nominatif.map((index) => {
        finalDataTable.push([
          index.kodeProduk,
          index.namaProduk,
          index.jmlRekening,
          `${
            index.plafond
              ? index.plafond.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")
              : 0
          }`,
          `${
            index.ostdPokok
              ? index.ostdPokok.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")
              : 0
          }`,
          `${
            index.ostdMargin
              ? index.ostdMargin.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")
              : 0
          }`,
        ]);
      });
      var pageWidth =
        doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

      doc
        .setFontSize(10)
        .text(`Laporan Nominatif Pembiayaan `, pageWidth / 2 - 1.1, 1);
      doc
        .setFontSize(8)
        .text(`Cabang     :   ${this.dataForm.officeName}`, 0.55, 2.3);

      doc.autoTable({
        columnStyles: {
          0: {
            halign: "center",
            fontSize: 6,
            font: "courier",
            minCellWidth: 0.8,
            fontStyle: "normal",
          },
          1: {
            halign: "center",
            fontSize: 6,
            minCellWidth: 0.7,
            font: "courier",
            fontStyle: "normal",
          },
          2: {
            halign: "center",
            fontSize: 6,
            font: "courier",
            fontStyle: "normal",
          },
          3: {
            halign: "center",
            fontSize: 6,
            font: "courier",
            fontStyle: "normal",
          },
          4: {
            halign: "center",
            fontSize: 6,
            font: "courier",
            fontStyle: "normal",
          },
          5: {
            halign: "center",
            fontSize: 6,
            font: "courier",
            fontStyle: "normal",
          },
          6: {
            halign: "center",
            fontSize: 6,
            font: "courier",
            fontStyle: "normal",
          },
        },
        headStyles: {
          halign: "center",
          fontSize: 6.4,
          fontStyle: "bold",
          font: "normal",
        },
        head: [headerFinal],
        theme: "striped",
        body: finalDataTable,
        margin: { top: 2.6 },
        halign: "center",
        didDrawPage: function (data) {
          data.settings.margin.top = 0.9;
        },
      });
      var pageHeight =
        doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
      doc.setFontSize(9).text("( Maker )", 0.55, pageHeight - 1);
      doc.setFontSize(9).text("( Taker )", 1.2, pageHeight - 1);
      doc.setFontSize(9).text("( Signer )", 1.82, pageHeight - 1);
      doc
        .setFontSize(9)
        .text(
          `Print By  :  ${
            JSON.parse(
              this.decryptBASE64(
                sessionStorage.getItem("@vue-session/auth-bundle")
              )
            ).userName
          } ( ${
            JSON.parse(
              this.decryptBASE64(
                sessionStorage.getItem("@vue-session/auth-bundle")
              )
            ).userCode
          } ) / ${this.getDateFormat(new Date())}`,
          0.55,
          pageHeight - 0.5
        );
      doc.autoPrint();
      const hiddFrame = document.createElement("iframe");
      hiddFrame.style.position = "fixed";
      // "visibility: hidden" would trigger safety rules in some browsers like safari，
      // in which the iframe display in a pretty small size instead of hidden.
      // here is some little hack ~
      hiddFrame.style.width = "1px";
      hiddFrame.style.height = "1px";
      hiddFrame.style.opacity = "0.01";
      const isSafari = /^((?!chrome|android).)*safari/i.test(
        window.navigator.userAgent
      );
      if (isSafari) {
        // fallback in safari
        hiddFrame.onload = () => {
          try {
            hiddFrame.contentWindow.document.execCommand("print", false, null);
          } catch (e) {
            hiddFrame.contentWindow.print();
          }
        };
      }
      hiddFrame.src = doc.output("bloburl");
      document.body.appendChild(hiddFrame);
    },
    getDateFormat(valDate) {
      var date = new Date(valDate);

      var dd = date.getDate();
      var mm = date.getMonth() + 1;
      var y = date.getFullYear();
      var time = date.toLocaleTimeString("en-GB");

      dd = dd.toString().length == 1 ? "0" + dd : dd;
      mm = mm.toString().length == 1 ? "0" + mm : mm;

      var formattedDate = dd + "-" + mm + "-" + y + " " + time;
      return formattedDate;
    },
    async changePaginationReportNominatif(event) {
      this.table.data.nominatif = [];
      this.property.listElement.nominatif.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "report",
          reqUrl: "report/nominatif-loan-product",
          payload: {
            officeCode: this.dataForm.cabang,
            page: event - 1,
            size: this.property.listElement.nominatif.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.nominatif = resp.data.data.content;
            this.property.listElement.nominatif.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetReportNominatif(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetReportNominatif(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.nominatif.downloading = false;
        }, timeout);
      }
    },
    failedGetReportNominatif(resp) {
      this.table.data.nominatif = [];
      this.property.listElement.nominatif.rows = 0;
      this.property.listElement.nominatif.message = resp.data.message;
    },
    handleErrorGetReportNominatif(error) {
      console.log(error.response);
      this.table.data.nominatif = [];
      this.property.listElement.nominatif.rows = 0;
      this.property.listElement.nominatif.currentPage = 1;
      this.property.listElement.nominatif.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async getReportNominatif() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      this.$buefy.dialog.confirm({
        message: "Proses Data ?",
        type: "is-success",
        onConfirm: async () => {
          this.property.animation.NominatifPembiayaan.isLoading = true;
          this.property.listElement.nominatif.downloading = true;
          this.table.data.nominatif = [];
          try {
            const resp = await this.$store.dispatch({
              type: "GET_LIST_DATA",
              endPoint: "report",
              reqUrl: "report/nominatif-loan-product",
              payload: {
                officeCode: this.dataForm.cabang,
                page: 0,
                size: this.property.listElement.nominatif.perPage,
              },
            });
            if (resp.data.code === "SUCCESS") {
              setTimeout(() => {
                this.table.data.nominatif = resp.data.data.content;
                this.property.listElement.nominatif.rows =
                  resp.data.data.totalElements;
                this.property.listElement.nominatif.currentPage =
                  resp.data.data.pageable.pageNumber + 1;
              }, 1000);
            } else {
              this.failedGetReportNominatif(resp);
            }
          } catch (error) {
            this.handleErrorGetReportNominatif(resp);
          } finally {
            setTimeout(() => {
              this.property.animation.NominatifPembiayaan.isLoading = false;
              this.property.listElement.nominatif.downloading = false;
            }, 1000);
          }
        },
      });
    },

    appendDefaultOptionSelect() {
      this.options.eksport.push(
        { value: "", text: "-- Pilih --" },
        { value: "EXCEL", text: "Excel" }
      );
    },

    routeToPageNominatifByKontrak(props) {
      sessionStorage.setItem(
        "OFFICE_CODE_NOMINATIF_PEMBIAYAAN_BY_AKAD",
        props.row.officeCode ? props.row.officeCode : ""
      );
      sessionStorage.setItem(
        "LOAN_CODE_NOMINATIF_PEMBIAYAAN_BY_AKAD",
        props.row.kodeProduk
      );
      this.$router.push("/report/nominatif-pembiayaan-by-kontrak");
    },
    helperGetOfficeDataForReference() {
      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_PARAMETER", {
          params: {
            officeName: "",
            page: 0,
          },
          url: "office",
        })
        .then((response) => {
          this.options.cabang = [{ value: "", text: "-- Semua Cabang --" }];
          response.data.data.content.map((index) => {
            const value = index.officeCode;
            const text = index.officeName;

            this.options.cabang.push({ text, value });
          });
        });
    },
  },
  mounted() {
    this.helperGetOfficeDataForReference();
    this.appendDefaultOptionSelect();
    this.helperGetUSerDataFromSession();
  },
};
